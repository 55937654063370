<template>
  <div class="container">
    <div class="mt-7 py-6">
      <div v-html="content" />
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      content: ""
    };
  },
  mounted() {
    this.getContent();
  },
  metaInfo: {
    title: "About Us"
  },
  methods: {
    getContent() {
      this.$axios
        .get("about-us")
        .then(res => {
          this.content = (res.data && res.data.content) || "";
        })
        .catch();
    }
  },
  components: {}
};
</script>
<style></style>
